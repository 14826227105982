:root {
  --input-border-color: #313c49;
  --auth-gray: #e2e4e9;
  --gray-text: #808893;
  --default-border-radius: 7px;
  --placeholder-color: #99a4b2;
  --select-icon-background: #2b343f;
  --error-red: #ff0061;
}

* {
  box-sizing: border-box;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.auth-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  justify-content: flex-start;
  width: 100%;
}

.button-style {
  background: var(--gradient-fall-back);
  border: none;
  font-size: inherit;
}

.button-icon {
  margin-right: 0.25em;
}

.button-press-effect {
  transition: transform 300ms;
}

.loginBtn {
  min-width: 100%;
  background-color: #625fff;
  min-height: 56px;
  border-radius: 100px !important;
}

.customGoogle,
.customLens {
  width: 100%;
  height: 52px;
  align-self: center;
  background-color: #1d273c;
  border-radius: 100px;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customGoogle {
  pointer-events: none;
}

.customLens {
  cursor: pointer;
}


@media only screen and (max-width: 670px) {
  .auth-container {
    max-width: 100%;
    min-height: 80vh;
  }

  .customGoogle {
    height: 45px;
  }

  .customLens {
    height: 45px;
  }

  .loginBtn {
    min-height: 45px;
  }
}

.button-press-effect:active,
.button-press-effect:hover,
.alt-button-press-effect:active {
  transition-duration: 150ms;
  opacity: 0.7;
}

.alt-button-press-effect {
  transition: transform 300ms var(--bouncy), background 150ms;
}

.alt-button-press-effect:active {
  background: hsla(var(--gradient-fall-back-h),
      var(--gradient-fall-back-s),
      var(--gradient-fall-back-l),
      0.5);
}

.alt-button-style {
  background: transparent;
  background: #1d273c;
  border-radius: 100px;
  height: 56px;
  border: none;
}

.button-style,
.alt-button-style {
  border-radius: #817eff;
  font-weight: 700;
  color: white;
  font-size: inherit;
}

button:disabled {
  pointer-events: none;
  opacity: 0.5;
}