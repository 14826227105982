/* @font-face {
  font-family: "Dilo";
  font-style: normal;
  font-weight:400;
  src: local("Dilo"), url("../fonts/DiloWorld.ttf") format("opentype");
} */


@font-face {
  font-family: 'Dilo';
  src: url('../fonts/DiloWorld.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}