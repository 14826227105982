.text-title {
  color: #e2e4e9;
}

.text-body {
  color: #808893;
}

.grid-display {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 12px;
  overflow-y: auto !important;
}

.hide-on-mobile.hide-on-mobile {
  opacity: 1;
  display: none;
}

Button {
  cursor: pointer;
}

.outerBorder {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;
  box-sizing: border-box;
  padding-top: 0;
}

.outerBorder p {
  margin-bottom: 41px;
}

.mainDiv {
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadImageandUrl {
  width: 100%;
  height: 80%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}

.uploadImage {
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 10px;
  min-height: 114px;
  border: 2px dashed #404F6D;
  border-radius: 10px;
  padding: 0 35px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  max-height: 475px;
}

.uploadImage:hover {
  cursor: pointer;
}

.imageListDiv {
  display: flex;
  height: 150px;
  width: 100%;
  flex-direction: row;
  margin: 2px;
  cursor: pointer;
  box-sizing: border-box;
  align-items: baseline;
  position: relative;
}

.imageListDiv:hover {
  border: 2px solid #35A6EB;
  border-radius: 5px;
}

.imageListDiv>img {
  opacity: 1;
  transition: opacity 0.2s;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.imageListDiv>img:hover {
  opacity: 0.7;
}

.credit {
  position: absolute;
  font-size: 0.8em;
  left: 7px;
  bottom: 7px;
  font-weight: 500;
  color: #ffffff;
  pointer-events: none;
  text-decoration: none;
  margin: 0 !important;
  visibility: hidden;
}

.searchSelectionDiv {
  width: calc(90% + 10px);
  height: 100%;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
  overflow-y: auto !important;
}

.searchImageDiv {
  width: 100%;
  height: 475px;
  max-height: 475px;
  padding: 2%;
  border: 1px solid darkgray;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.searchQueryNSelectionDiv {
  height: 100%;
  width: calc(100% - 190px);
  max-width: calc(100% - 190px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  position: relative;
}

.cameraNA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 5.5em;
  color: #808893;
}

.cameraNA p {
  font-size: 0.875em;
  margin: 0;
  color: inherit;
  text-align: center;
}

.cameraNA p:not(:first-of-type) {
  margin-top: 0.75em;
}

.cameraCaptureBtn {
  position: absolute !important;
  bottom: 20px;
  right: 20px;
}

.cameraCaptureBtn {
  color: white;
}

.capturedImageDiv {
  margin-top: 30px;
  width: 260px;
  height: 200px;
  border-radius: 5px;
  cursor: pointer;
}

.selectedImage {
  margin-top: 3em;
  margin-bottom: 1em;
  width: 190px;
  max-width: 190px;
  border: 3px solid #3fa9f5;
  border-radius: 10px;
  cursor: pointer;
}

.webcamComp {
  width: 100%;
  border-radius: 5px;
}

.uploadHeader {
  font-size: 14px;
  font-weight: 900;
  margin: 0;
  line-height: 24px;
  letter-spacing: -0.7px;
  color: #ffffff;
}

.uploadImageDragged {
  width: 100%;
  padding: 2%;
  border: 1px solid darkgray;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 475px;
}

.unsplashLogo {
  max-width: 360px;
  max-height: 35px;
  vertical-align: baseline;
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.selectedImageDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 190px;
  height: 100%;
}

.selectedImageDiv>a {
  font-weight: 500;
  font-size: 1.2em;
  color: #595959;
  margin: 5px !important;
  text-decoration: none;
}

.selectedImageDiv>p {
  margin: 0 !important;
  font-size: 0.8em;
}

.selectedImageDiv>p>a {
  text-decoration: none;
}

.selectedImageDiv>p>a:visited {
  color: blue;
}

.uploadImagetext {
  font-size: 15px;
  font-weight: 100;
}

.uploadUrl {
  width: 100%;
  height: 100%;
  max-height: 475px;
  border: 1px solid darkgray;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.uploadUrl>p,
.uploadUrl>form>p {
  font-size: 1.6em;
  font-weight: 700;
  color: #898989;
  margin-top: 50px;
}

.proceedBtn {
  margin-top: 150px !important;
  margin-bottom: 10px !important;
}

.proceedBtn>span {
  color: #fff;
  font-weight: 700;
  font-size: 1.2em;
}

.selectBtn {
  width: 100%;
  background-color: #884bff !important;
  color: white;
  height: 45px;
}

.selectBtn>span {
  color: #fff;
  font-weight: 700;
}

.uploadBtn {
  pointer-events: auto;
}

.uploadBtnDragged {
  pointer-events: none;
}

.uploadBtnDragged>span {
  color: #fff;
  font-weight: 700;
  font-size: 1.2em;
}

.uploadBtn>span {
  color: #fff;
  font-weight: 700;
  font-size: 1.2em;
}

.svgUpload {
  height: 350px;
  width: 350px;
  color: #dddddd !important;
  pointer-events: none;
}

.svgUploadDragged {
  height: 350px;
  width: 350px;
  color: #3fa9f5 !important;
  pointer-events: none;
}

.cameraNAIcon {
  color: #dddddd !important;
  pointer-events: none;
  transform: scale(0.9);
}

.tabButton>button {
  background: #3fa9f5;
  color: white;
  border-radius: 5px;
  height: 28px;
  border: none;
  margin-bottom: 20px;
}

.tabButton>button>span {
  color: white;
}

.selectedTabbtn {
  border: 1px solid #595959 !important;
}

form {
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.loaderSearch {
  position: absolute;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.backdropDiv {
  width: 100%;
  height: 100%;
  background: rgb(150, 150, 150, 0.4);
  position: absolute;
  z-index: 1000;
}

.loadingContainer {
  position: relative;
  width: 100%;
  height: 112px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingIcon {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  z-index: 1;
}

.select-img {
  font-size: 25px;
  font-weight: bold;
  color: #595959;
}

@media only screen and (max-width: 500px) {
  .uploadImageandUrl {
    flex-direction: column;
  }

  .uploadImage {
    width: 100%;
    margin-bottom: 20px;
  }

  .uploadUrl {
    width: 100%;
    padding: 2%;
  }
}

#urlInputUpload {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #1C263A;
  background-color: #151E31;
  color: #808893;
  padding: 5px;
}

.pasteURLInput {
  text-align: center;
  width: 90%;
  font-size: 1.2em;
  border-color: gainsboro;
}

@media only screen and (max-width: 1000px) {
  .back {
    width: 100% !important;
  }
}

.back {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100px;
  height: 50px;
}

.urlTextUpperDiv {
  width: calc(100% - 30px);
  text-align: center;
}

.back svg {
  height: 50px;
  width: 30px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .uploadImageandUrl {
    flex-direction: column;
  }

  .uploadImage {
    width: 100%;
    margin-bottom: 20px;
  }

  .uploadUrl {
    width: 100%;
    padding: 2%;
    height: 100%;
  }

  button {
    font-size: 30px;
  }

  .select-img {
    font-size: 40px !important;
  }

  .svgUpload {
    height: 100px;
    width: 100px;
  }

  .back svg {
    height: 50px;
    width: 50px;
  }
}

.tabButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.tabButton button {
  width: 30%;
  min-width: 100px;
  max-width: 300px;
}

.tabButton button:active {
  background-color: blue;
}

.selected-button {
  height: 32px;
  box-shadow: 0px 1px 3px dodgerblue;
  color: #fff;
}

@media (max-width: 760px) {
  .webcamComp {
    height: 100vh;
    object-fit: cover;
    border-radius: 0;
  }

  .downloadOptionButtonGroup>button {
    width: 120px;
    background-color: #120717;
    color: #adb3c0;
    border: 0;
    border-radius: 7px;
    font-size: 14px;
  }

  .imageListDiv>img {
    border-radius: 15px;
  }

  .cameraNA p {
    text-align: center;
    font-size: 14px;
  }

  .cameraNA p:not(:first-of-type) {
    color: white
  }
}

@media (max-width: 600px) {
  .imageListDiv {
    width: calc((100% - 8px) / 2);
  }
}

@media (max-width: 450px) {
  .imageListDiv {
    width: 100%;
  }
}

.downloadOptionButtonGroup>button {
  width: 120px;
  background-color: #1C263A;
  color: #adb3c0;
  border: 0;
  border-radius: 7px;
}

.downloadOptionButtonGroup>button:hover {
  background-color: var(--gradient-fall-back);
  color: white;
}

@media (min-width: 800px) {
  .masonry-grid {
    display: none;
  }

  .hide-on-mobile.hide-on-mobile {
    opacity: unset;
    display: flex;
  }
}

.skeleton-main-color {
  background: linear-gradient(0.25turn,
      transparent,
      hsla(var(--primary-color-hsl-values), 0.9) 40%,
      transparent 80%);
  position: absolute;
  width: 30%;
  height: 100%;
  z-index: -1;
  transform: translateX(-100%);
  animation: skeleton-loading 1.5s infinite;
  filter: blur(7px);
}

@keyframes skeleton-loading {
  /* to {
    transform: translateX(350%);
  } */
}