html {
  /* 10 (our default fontsize)/16 (web default fontsis) * 100% = 62.5% fontsize */
  /* font-size: 62.5%; */
}

/* set the global font-size across different screen sizes */

body,
div {
  margin: 0;
  font-family: Inter, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #151e31;
  margin: 0;
  padding-right: 0 !important;
}

a {
  text-decoration: unset;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/Inter-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "InterItalic";
  src: url("../fonts/Inter-LightItalic.otf") format("otf");
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.2;
}

.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.5),
    rgba(#fff, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite !important;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

.img-hover-zoom:hover img {
  transition: all 0.3s;
}
.img-hover-zoom:hover img {
  filter: brightness(70%);
}
@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}


