@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: local("Inter"), url("../fonts/Inter-Thin.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local("Inter"), url("../fonts/Inter-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter"), url("../fonts/Inter-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: local("Inter"), url("../fonts/Inter-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter"), url("../fonts/Inter-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: local("Inter"), url("../fonts/Inter-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local("Inter"), url("../fonts/Inter-ExtraBold.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: local("Inter"), url("../fonts/Inter-Black.ttf") format("opentype");
}