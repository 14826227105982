:root {
  --bouncy: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  --sidebar-background: #0c0e14;
  --primary-black-rgb: #12161a;
  --primary-black-hsl: hsl(210, 18.2%, 8.6%);
  --primary-black-h: 210;
  --primary-black-s: 18.2%;
  --primary-black-l: 8.6%;
  --primary-gray: #808893;
  --main-gradient: linear-gradient(285.87deg, #7075ff 3.44%, #35a6eb 71.68%, #01ffff 125.89%);
  --gradient-fall-back: #35a6eb;
  --gradient-fall-back-h: 202.7;
  --gradient-fall-back-s: 82%;
  --gradient-fall-back-l: 56.5%;
  --gradient-fall-back-hsl: 202.7, 82%, 56.5%;
  --primary-color-rgb-values: 20, 22, 30;
  --primary-color-hsl-values: 228, 20%, 9.8%;
  --primary-font: Inter, Arial, Helvetica, sans-serif;
  --secondary-font: 'Dilo World';
}

@import url('../fonts/inter_font.css');
@import url('../fonts/dilo_font.css');

body,
#root {
  font-family: Inter, Arial, Helvetica, sans-serif;
}

.text-gradient {
  color: var(--gradient-fall-back);
}

.text-gradient-hover {
  transition: color 150ms, background 150ms;
}

.text-gradient-hover:hover {
  color: var(--gradient-fall-back);
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) and
  (-webkit-text-fill-color: transparent) {
  .text-gradient,
  .text-gradient-hover:hover {
    background: var(--main-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.background-gradient-animation:hover,
.text-gradient-animation {
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.text-gradient-animation {
  animation-duration: 3s;
}

@keyframes gradient {
  0%,
  100% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  75% {
    background-position: 0% 50%;
  }
}

.center-to-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-border {
  border: 0;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.big-font {
  font-size: 45px;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 4px;
}

.m-2 {
  margin: 8px;
}

.m-3 {
  margin: 12px;
}

.m-4 {
  margin: 16px;
}

.m-5 {
  margin: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 16px;
}

.ml-5 {
  margin-left: 20px;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-4 {
  margin-right: 16px;
}

.mr-5 {
  margin-right: 20px;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

/* Modals */
.input-shadow {
  box-shadow: 0 0 3pt 1pt #a0158a;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 4px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 12px;
}

.pt-4 {
  padding-top: 16px;
}

.pt-5 {
  padding-top: 20px;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 4px;
}

.p-2 {
  padding: 8px;
}

.p-3 {
  padding: 12px;
}

.p-4 {
  padding: 16px;
}

.p-5 {
  padding: 20px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 4px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-5 {
  padding-bottom: 20px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 4px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 12px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-5 {
  padding-left: 20px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 4px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-3 {
  padding-right: 12px;
}

.pr-4 {
  padding-right: 16px;
}

.pr-5 {
  padding-right: 20px;
}

/* Horizontal Scroller */
.menu-item {
  margin: 10px 36px 10px 0px;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-wrapper {
  overflow: hidden !important;
}

.horizontal-menu {
  width: 572px;
}

.menu-wrapper--inner {
  position: relative;
  right: 75px;
}

:focus {
  outline: none !important;
}
.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.validateOutsideBox {
  right: -32px !important;
}
@media screen and (max-width: 600px) {
  .position-relative {
    margin-left: auto;
    margin-right: auto;
  }
  .big-font {
    font-size: 32px;
  }
}
.MuiNativeSelect-selectMenu {
  display: none !important;
}

.form-button {
  font-size: 1em;
  font-weight: bolder;
  color: #fff;
  background: var(--main-gradient);
  width: 100%;
  border: none;
  margin-top: 2.3vmax;
  border-radius: 4px;
  padding: 0.8em 25px;
  cursor: pointer;
  text-align: center;
  transition: background 300ms;
}

.form-button:hover {
  animation: gradient 10s ease infinite;
  background-size: 400% 400%;
}

.line-separator {
  margin: 20px 0;
  max-width: 580px;
  width: 95%;
  text-align: center;
  border-bottom: 1px solid;
  border-color: #212B41;
  color: #808080;
}


.social-login-wrapper {
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0.2em 0;
  border-radius: 4px;
  border: 3px solid #edf0f7;
  margin-top: 0.8em;
  display: flex;
  place-content: center;
  cursor: pointer;
}

.main-auth-text {
  color: var(--primary-black-rgb);
  font-size: 2.7em;
  line-height: 1;
  text-align: left;
  margin-bottom: 0.6em;
}

.main-auth-text + p {
  font-size: 0.875em;
  line-height: 0.75;
  text-align: left;
  margin-top: 0;
  transform: translateX(0.57em);
  color: hsl(var(--primary-black-h), var(--primary-black-s), 70%);
}

.reset-password-wrapper {
  width: 40%;
  margin: 0 auto;
  font-size: 1rem;
  padding: 2em;
}

.reset-password-wrapper > h3 {
  margin-bottom: 0.5em;
  font-size: 2em;
}

.server-error,
.server-success {
  text-align: center;
  padding: 10px;
  z-index: 1;
  border-radius: 4px;
}

.server-error,
.auth-error {
  color: #ff006190;
  background: #ff006120;
}

.server-success {
  background: #00df52;
  color: #00df5240;
}

svg.validation-icon {
  top: 50%;
  left: 91%;
  transform: translate(0%, -50%) scale(1.5);
}

div.validation-warning {
  font-size: '0.8em';
}

.swiper-slide {
  width: min(168px, max(23vmin, calc(100% / 3)));
}

.toasts.toasts {
  background: hsla(var(--primary-black-h), var(--primary-black-s), 40%, 0.3);
  color: white;
  padding: 1em 0.5em;
}

@media screen and (max-width: 550px) {
  svg.validation-icon {
    left: 88%;
  }
}

@keyframes riseUp {
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Emoji Popup Styling*/
.emoji-mart-dark .emoji-mart-search input,
.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #161c22;
  color: #abb3bf;
}
.emoji-mart-scroll::-webkit-scrollbar {
  width: 0.5em;
}

.emoji-mart-scroll::-webkit-scrollbar-track {
  background-color: #161c22;
}
.emoji-mart-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #35a6eb;
  height: 2.5em;
}
